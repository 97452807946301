import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, IconButton, TextField, Tooltip } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useSearchParams } from 'react-router-dom';

import './style.scss';
import { SelectBox } from 'components/form';

export default function BoardSearchBox({ getSearchText }) {
  // hooks
  const [searchParams] = useSearchParams();
  const region = searchParams.get('region');

  // state
  const [searchText, setSearchText] = useState(searchParams.get('search') || ''); // 검색어
  const [selectedValue, setSelectedValue] = useState(''); // 선택한 검색 카테고리
  const [selectOptions, setSelectOptions] = useState([]); // 검색 카테고리 목록
  const isFirstRender = useRef(true);

  // 검색 Method
  const handleSearch = () => {
    getSearchText({ text: searchText, type: selectedValue });
  };

  // mount 및 boardType 변경 시 검색 카테고리 업데이트
  useEffect(() => {
    setSelectOptions(['제목', '내용']);
    setSelectedValue(searchParams.get('searchType') || '제목');
  }, [searchParams]);

  // mount 및 지역 변경 시 검색어 초기화
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // 마운트 이후 플래그 설정
      return;
    }

    setSearchText(searchParams.get('search') || '');
  }, [region, searchParams]);

  return (
    <>
      <Flex align="center" justify="end" gap="var(--space-3)" className="board-search-box">
        <Box flexBasis="200px" flexShrink="0">
          <SelectBox
            size="3"
            placeholder="선택해 주세요."
            value={selectedValue}
            options={selectOptions}
            onChange={value => setSelectedValue(value)}
          />
        </Box>
        <TextField.Root
          placeholder="검색어를 입력해 주세요."
          color="gray"
          title="내용 입력 창"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onKeyUp={e => {
            if (e.key === 'Enter') handleSearch();
          }}
        />
        <IconButton className="rt-variant-text" onClick={handleSearch}>
          {searchText.length > 0 || searchText.length === null ? (
            <MagnifyingGlassIcon width={36} height={36} />
          ) : (
            <Tooltip content="검색어를 입력해 주세요.">
              <MagnifyingGlassIcon width={36} height={36} />
            </Tooltip>
          )}
        </IconButton>
      </Flex>
    </>
  );
}
