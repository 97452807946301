import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Box } from '@radix-ui/themes';
import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import SortByYear from 'components/common/sortByYear';
import { infographicData } from './data';

import { useSelector } from 'react-redux';
import { selectDevice, selectSelectedInfoYear } from 'store/slices/common';

import './style.scss';
import NoData from 'components/common/noData';

export default function PerformanceDetail() {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const year = useSelector(selectSelectedInfoYear);

  const { state } = useLocation();

  // console.log(id === String(el.idx));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Visual category="business" pageTitle="사업별 성과 현황" title="사업" eng="Dreams Business" />
      <section className="performance-infographic-wrap">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title={state} />
          {infographicData[year]?.map(
            (el, i) =>
              state === el.title && (
                <Box key={i}>
                  {el.isHistory && <SortByYear path="info" />}
                  {!el.pc && year === '2024년' ? (
                    <img
                      key={i}
                      src={isMobile ? infographicData['2023년'][i].mo : infographicData['2023년'][i].pc}
                      alt={infographicData['2023년'][i].alt}
                    />
                  ) : !el.pc && year === '2023년' ? (
                    <img
                      key={i}
                      src={isMobile ? infographicData['2024년'][i].mo : infographicData['2024년'][i].pc}
                      alt={infographicData['2024년'][i].alt}
                    />
                  ) : (!el.pc && !isMobile) || (!el.mo && isMobile) ? (
                    <Box pt={isMobile ? 'var(--space-6)' : 'var(--space-10)'}>
                      <NoData />
                    </Box>
                  ) : (
                    <img key={i} src={isMobile ? el.mo : el.pc} alt={el.alt} />
                  )}
                </Box>
              )
          )}
        </Container>
      </section>
    </>
  );
}
