import React from 'react';

import './style.scss';

export default function Skipnav() {
  return (
    <>
      <div id="skipnav">
        <a href="#contents">본문 바로가기</a>
        <a href="#menu">메뉴 바로가기</a>
      </div>
    </>
  );
}
