import React from 'react';
import BoardDetail from 'components/board/boardDetail';

export default function NoticeDetail() {
  const boardType = 'notice';
  return (
    <BoardDetail
      pageTitle="성과보고회 공지사항"
      visualProps={{
        category: 'notice',
        title: '성과보고회 공지사항',
        eng: 'DREAMS NOTICE'
      }}
      boardType={boardType}
    />
  );
}
