import { Navigate, Route, useLocation } from 'react-router-dom';

import Main from 'pages/main';
import Login from 'pages/login';
import AuthEmail from 'pages/login/auth';
import Error from 'components/common/error';

// 소개
import LocalDigitalIndustry from 'pages/introduction/localDigitalIndustry'; // 지역 디지털 산업
import LocalDigitalInnovation from 'pages/introduction/localDigitalInnovation'; // 지역디지털본부 소개
import AboutDreams from 'pages/introduction/aboutDreams'; // Dreams란
// 정책
import ImportantPolicy from 'pages/policy/importantPolicy'; // 중요정책
import PropulsionSystem from 'pages/policy/propulsionSystem'; // 추진체계
// 사업
import Performance from 'pages/business/performance'; // 사업별 성과 현황
import PerformanceDetail from 'pages/business/performance/Detail'; // 사업별 성과 현황 - 인포그래픽
// import LocalBusiness from 'pages/business/localBusiness'; // 지역별 사업 현황
import Survey from 'pages/business/survey'; // 실태조사
// 솔루션뱅크
import SolutionInfo from '../pages/solution/introduce'; // 사업 소개
import ProjectList from 'pages/solution/projectList'; // 프로젝트 목록
import ProjectListDetail from 'pages/solution/projectList/Detail'; // 프로젝트 목록 - 상세
// 우수사례
import PerformanceReport from 'pages/bestPractices/performanceReport'; // 성과보고회
import ManOfMerit from 'pages/bestPractices/manOfMerit'; // 유공자
import Contest from 'pages/bestPractices/contest'; // 공모전

// 공지사항
import Notice from 'pages/performance/notice'; // 공지사항
import NoticeDetail from 'pages/performance/notice/detail';
import VideoGallery from 'pages/performance/videoGallery'; // 우수영상관
import VideoGalleryDetail from 'pages/performance/videoGallery/detail.jsx';

const RouteList = [
  {
    path: '/',
    element: <Main />,
    private: false,
    title: 'DREAMS'
  },
  {
    path: '/login',
    element: <Login />,
    private: false,
    title: '로그인'
  },
  {
    path: '/findPassword',
    element: <AuthEmail />,
    private: false
  },
  {
    path: '/findId',
    element: <AuthEmail />,
    private: false
  },
  {
    path: '/introduction/localDigitalIndustry',
    element: <LocalDigitalIndustry />,
    private: false,
    title: '지역 디지털 산업 | 소개 | DREAMS'
  },
  {
    path: '/introduction/localDigitalInnovation',
    element: <LocalDigitalInnovation />,
    private: false,
    title: '지역 디지털 본부 소개 | 소개 | DREAMS'
  },
  {
    path: '/introduction/aboutDreams',
    element: <AboutDreams />,
    private: false,
    title: 'DREAMS 란? | 소개 | DREAMS'
  },
  {
    path: '/policy/importantPolicy',
    element: <ImportantPolicy />,
    private: false,
    title: '중요 정책 | 정책 | DREAMS'
  },
  {
    path: '/policy/propulsionSystem',
    element: <PropulsionSystem />,
    private: false,
    title: '추진 체계 | 정책 | DREAMS'
  },
  // {
  //     path: '/business/regional',
  //     element: <LocalBusiness />,
  //     private: false
  // },
  {
    path: '/business/performance',
    element: <Performance />,
    private: false,
    title: '사업별 성과 현황 | 사업 | DREAMS'
  },
  {
    path: '/business/performance/detail',
    element: <PerformanceDetail />,
    private: false,
    title: state => `${state || '사업 상세'} | 사업별 성과 현황 | 사업 | DREAMS`
  },
  {
    path: '/business/survey',
    element: <Survey />,
    private: false,
    title: '지역 디지털 산업 생태계 실태조사 | 사업 | DREAMS'
  },
  {
    path: '/solution/info',
    element: <SolutionInfo />,
    private: false,
    title: '사업 소개 | 솔루션 뱅크 | DREAMS'
  },
  {
    path: '/solution/projectList',
    element: <ProjectList />,
    private: false,
    title: '프로젝트 목록 | 솔루션 뱅크 | DREAMS'
  },
  {
    path: '/solution/projectList/detail/:id',
    element: <ProjectListDetail />,
    private: true,
    title: '상세 | 프로젝트 목록 | 솔루션 뱅크 | DREAMS'
  },
  {
    path: '/bestPractices/performanceReport',
    element: <PerformanceReport />,
    private: false,
    title: '성과보고회 | 우수사례 | DREAMS'
  },
  {
    path: '/bestPractices/manOfMerit',
    element: <ManOfMerit />,
    private: false,
    title: '유공자 | 우수사례 | DREAMS'
  },
  {
    path: '/bestPractices/contest',
    element: <Contest />,
    private: false,
    title: '공모전 | 우수사례 | DREAMS'
  },
  {
    path: '/notice',
    element: <Notice />,
    private: false,
    title: '성과보고회 공지사항 | 공지사항 | DREAMS'
  },
  {
    path: '/notice/detail/:id',
    element: <NoticeDetail />,
    private: false,
    title: '상세 | 성과보고회 공지사항 | 공지사항 | DREAMS'
  },
  {
    path: '/videoGallery',
    element: <VideoGallery />,
    private: false,
    title: '성과보고회 우수 영상관 | 공지사항 | DREAMS'
  },
  {
    path: '/videoGallery/detail/:id',
    element: <VideoGalleryDetail />,
    private: false,
    title: '상세 | 성과보고회 우수 영상관 | 공지사항 | DREAMS'
  },
  {
    path: '*',
    element: <Error />,
    private: false,
    fullScreen: true
  }
];

const publicAuthRoutes = ['/login', '/findId', '/findPassword']; // 로그인 시 접근 불가능 path

const RedirectRoute = ({ isLogin, element }) => {
  return isLogin ? <Navigate to="/" replace /> : element;
};

const ProtectedRoute = ({ element, isLogin }) => {
  const location = useLocation();
  return isLogin ? element : <Navigate to="/login" replace state={{ from: location }} />;
};

export const generateRoutes = isLogin =>
  RouteList.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      element={
        publicAuthRoutes.includes(route.path) ? (
          <RedirectRoute element={route.element} isLogin={isLogin} />
        ) : route.private ? (
          <ProtectedRoute element={route.element} isLogin={isLogin} />
        ) : (
          route.element
        )
      }
    />
  ));

export default RouteList;
